// app/routes/fonts.tsx
import { MetaFunction, json, useLoaderData } from "@remix-run/react";
import { useEffect, useState } from "react";
import { TabMenu } from "~/components/TabMenu";
import Header from "~/components/Header";
import Sidebar from "~/components/Sidebar";
import { MainCarousel } from "~/components/MainCarousel";
import { currentUser, isAuthenticated, isProUser } from "~/features/AuthHelper";

export const loader = async () => {
  // Fetch the data from the API
  const response = await fetch(`https://backend.fontcert.com/api/active-fonts/?format=json`);

  const allFonts: any = await response.json();
  if (!allFonts) {
    return json({ error: "Fonts not found" }, { status: 404 });
  }
  // Return the data as JSON
  return json({ allFonts });
};
export const meta: MetaFunction = () => {
  return [
    { title: "Home | Fontcert" },
    {
      property: "og:title",
      content: "Home | Fontcert",
    },
    {
      name: "description",
      content: "Fontcert - Curated collection of Fonts & Foundries",
    },
  ];
};

export default function Index() {
  let { allFonts } = useLoaderData<typeof loader>() as { allFonts: any };
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const authenticated = isAuthenticated();
  const userData = currentUser();
  const proUser = isProUser();
  
  useEffect(() => {
    setLoggedIn(authenticated);
  }, [isAuthenticated()]);

  useEffect(() => {
    setUser(userData);
  }, [currentUser()]);

  // sort the data based on the last modified date, also limit to first 2 if not logged in
  allFonts.sort(
    (
      a: { last_updated: string | number | Date },
      b: { last_updated: string | number | Date }
    ) => new Date(b.last_updated).getTime() - new Date(a.last_updated).getTime()
  );

  // get total number of fonts available
  const totalFonts = allFonts.length;

  // getting first image from each fonts to display it in the homepage - limits to 50 items.
  const homepageImages = allFonts.slice(0, 50);

  if (!loggedIn) {
    // if not logged in, take only 10 items
    allFonts = allFonts.slice(0, 10);
  }

  if (loggedIn && proUser !== "PRO") {
    //non-pro users, take only 20 items
    allFonts = allFonts.slice(0, 20);
  }

  return (
    <>
      <Header user={user} proUser={proUser === "PRO"} />
      <div className={"page-layout home-page"}>
        <TabMenu tabs={["Fonts", "Foundries"]} />
        <div className="layout-wrap">
          <Sidebar fontData={allFonts} authenticated={loggedIn} proUser={proUser === "PRO"} totalFonts={totalFonts} />
          <div className={"main-content"}>
            <MainCarousel
              page="home"
              key={`home`}
              carouselItems={homepageImages}
            />
          </div>
        </div>
      </div>
    </>
  );
}
